import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import Shimmer from "react-shimmer-effect";

import useSize from "@react-hook/size";

import { bright_70, increaseBrightness } from "external/helpers/adjustColor";
import ResponsiveHelper from "external/helpers/responsive.helper";
import analyticsPubSub, {
  ANALYTICS_EVENTS,
} from "external/services/analyticsPubSub";
import TextHelpers from "external/helpers/text.helper";

import LazyImage from "../LazyImage";
import "./index.component.scss";

export default function ImageCrousel({
  data,
  loading,
  primaryColor,
  screenWidth,
  isMobileView,
  widthToHeightRatio = 5 / 2,
}) {
  const containerRef = useRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [slideMarginLeft, setSlideMarginLeft] = useState(0);
  const [imageWidth] = useState(
    ResponsiveHelper.getBannerCdnWidthFromScreenWidth(screenWidth),
  );
  const autoScroll = useRef();
  const [width] = useSize(containerRef);
  const height = ResponsiveHelper.getHeightFromWidthHeightRatio(
    width || screenWidth,
    widthToHeightRatio,
  );

  // variables
  const isCarouselView = data && data.length > 1;
  const intervalInMS = 3000;
  const slideLength = 100 / data?.length;

  const resetTimer = () => {
    clearInterval(autoScroll.current);
    autoScroll.current = setInterval(() => {
      handleArrowClick(1);
    }, intervalInMS);
  };

  const handleArrowClick = useCallback(
    (index) => {
      if (index > 0) {
        setActiveSlideIndex((current) =>
          current < data.length - 1 ? current + 1 : 0,
        );
      } else {
        setActiveSlideIndex((current) =>
          current > 0 ? current - 1 : data.length - 1,
        );
      }
      resetTimer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length],
  );

  useEffect(() => {
    if (!loading) {
      autoScroll.current = setInterval(() => {
        handleArrowClick(1);
      }, intervalInMS);
    }
    return () => clearInterval(autoScroll.current);
  }, [loading, screenWidth, handleArrowClick]);

  useEffect(() => {
    //makes sure carousel doesn't get effected by language change
    containerRef.current.dir = "ltr";
  }, []);

  useEffect(() => {
    const marginLeft = slideLength * activeSlideIndex;
    setSlideMarginLeft(marginLeft);
  }, [data, slideLength, activeSlideIndex]);

  const handleBannerClick = () => {
    analyticsPubSub.publish(ANALYTICS_EVENTS.BANNER_CLICKED);
  };

  if (loading) {
    return (
      <div
        className="image-carousel-wrapper"
        ref={containerRef}
        style={{ height }}
      >
        <div className="image-carousel-container">
          <Shimmer>
            <div className="carousel-image-placeholder" />
          </Shimmer>
        </div>
      </div>
    );
  }

  return (
    <div
      className="image-carousel-wrapper"
      ref={containerRef}
      style={{ height }}
    >
      <div className="image-carousel-container">
        {data.map((image, index) => (
          <ImageSlide
            key={image.id}
            imageData={image}
            index={index}
            isMobileView={isMobileView}
            onClick={handleBannerClick}
            activeSlideIndex={activeSlideIndex}
            imageWidth={imageWidth}
            markup={image.markups}
          />
        ))}

        {isCarouselView ? (
          <Fragment>
            <button className="prev" onClick={() => handleArrowClick(-1)}>
              &#10094;
            </button>
            <button className="next" onClick={() => handleArrowClick(1)}>
              &#10095;
            </button>
          </Fragment>
        ) : null}
      </div>

      {isCarouselView ? (
        <div
          style={{
            backgroundColor: increaseBrightness(primaryColor, bright_70),
          }}
          className="carousel-indicator"
        >
          <div
            className="slide"
            style={{
              marginInlineStart: `${slideMarginLeft}%`,
              width: `${slideLength}%`,
              backgroundColor: primaryColor,
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

function ImageSlide({
  imageData,
  index,
  onClick,
  activeSlideIndex,
  imageWidth,
  markup,
  isMobileView,
}) {
  return (
    <div
      className="carousel-slide"
      onClick={onClick}
      style={{ display: activeSlideIndex === index ? "block" : "none" }}
    >
      <LazyImage
        rel="preload"
        className="carousel-image"
        alt={`banner-${imageData.id}`}
        // not using for desktop because of a bug in CDN image resizing that needs
        // to be fixed. https://github.com/urbanpiper-engineering/codex-serverless-image-resize/issues/3
        src={
          isMobileView
            ? `${imageData.image}?${
                imageData.image.split(".").pop() === "gif"
                  ? ""
                  : `width=${imageWidth}`
              }`
            : imageData.image
        }
        // imageSizes: [16, 32, 64, 128, 180, 280, 320, 640, 720, 960, 1080 ],
        size={isMobileView ? 640 : 1080}
        imageProps={{
          fetchpriority: "high",
          loading: "eager",
          decoding: "sync",
        }}
      />
      <div
        className="carousel-markup"
        dangerouslySetInnerHTML={{ __html: TextHelpers.htmlDecode(markup) }}
      />
    </div>
  );
}
