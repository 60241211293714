import React, { useState, useEffect } from "react";
import ItemCard from "../../item-card/index.component";
import EmptySearchResult from "../EmptySearchResult";
import { ITEM_MINIMAL_DATA } from "../../../mock-data/index.mock-data";
import ResponsiveHelper from "external/helpers/responsive.helper";
import { COLOR_WHITE } from "../../../constants/colors.constants";
import SectionTitle from "./SectionTitle";

function Section({
  category,
  primaryTextColor,
  secondaryColor,
  primaryColor,
  secondaryTextColor,
  selectedFontFamily,
  mainCategoryRefs,
  observerConfig,
  onCategoryActivation,
  fetchVisibleCategory,
}) {
  const [isSectionActive, setIsSectionActive] = useState(false);

  const screenWidth = window.innerWidth;
  const isMobileView = ResponsiveHelper.isMobile(screenWidth);

  const handleMainCategoryIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        onCategoryActivation(category);
      }
    });
  };

  const checkIfVisibleInViewPort = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        fetchVisibleCategory(category.id);
        setIsSectionActive(true);
      }
    });
  };

  useEffect(() => {
    const mainCategoryObserver = new IntersectionObserver(
      handleMainCategoryIntersection,
      observerConfig,
    );
    mainCategoryObserver.observe(mainCategoryRefs[category.id].current);

    const mainCategoryVisibilityObserver = new IntersectionObserver(
      checkIfVisibleInViewPort,
      {
        root: null,
        rootMargin: "0px 0px 600px 0px",
        threshold: 0.4,
      },
    );
    mainCategoryVisibilityObserver.observe(
      mainCategoryRefs[category.id].current,
    );

    return () => {
      mainCategoryObserver.disconnect();
      mainCategoryVisibilityObserver.disconnect();
    };
    /**
     * we only need observerConfig and id, including the rest will give
     * raise to jankiness
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerConfig, category.id]);

  const categoryWiseItems = (category) => (
    <React.Fragment>
      {category?.sub_categories && category.sub_categories?.length > 0
        ? null
        : category.description &&
          !isMobileView && (
            <div
              dangerouslySetInnerHTML={{
                __html: `${category.description}`,
              }}
              style={{
                color: secondaryTextColor,
                fontFamily: selectedFontFamily,
                fontWeight: "normal !important",
                margin: "8px 0",
              }}
            />
          )}

      {category.items?.length && isSectionActive ? (
        <div className="category-item-list">
          {category.items.map((item) => (
            <ItemCard
              item={item}
              key={item.id}
              secondaryColor={secondaryColor}
              primaryColor={primaryColor}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              selectedFontFamily={selectedFontFamily}
            />
          ))}
        </div>
      ) : category.name === "Search Results" && !category.items?.length ? (
        <EmptySearchResult
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryColor}
        />
      ) : (
        renderItemPlaceholder()
      )}
    </React.Fragment>
  );

  return (
    <div
      data-cat-slug={`s-${category.id}`}
      id={category.id}
      ref={mainCategoryRefs[category.id]}
      className="element"
    >
      <div
        style={{
          backgroundColor: COLOR_WHITE,
          padding: "0px 12px",
          marginBottom: "12px",
        }}
      >
        <SectionTitle
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          category={category}
        />

        {categoryWiseItems(category)}
      </div>
    </div>
  );
}

const renderItemPlaceholder = () => {
  return (
    <div className="item-list-placeholder">
      <div className="element-placeholder">
        <div className="category-item-list-placeholder">
          {ITEM_MINIMAL_DATA.map((_, index) => (
            <ItemCard key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
